<template>

  <a-row>
    <a-card class="general_card" :bordered="false" title="Kurs Listesi" :loading="loading">
      <template #extra>
        <UnorderedListOutlined/>

        <a-button class="ant-btn-primary" @click="showMyModal()">
          <PlusOutlined/>
          Ekle
        </a-button>
        <!--        <router-link class="ant-btn-primary" tag="a" to="/new_company">-->
        <!--          <PlusOutlined/>-->
        <!--          Ekle-->
        <!--        </router-link>-->

      </template>


      <a-col :span="24">
        <AddOrEditCourse v-if="!loading" ref="addOrEditCourses" @loadCourses="getCourses()"/>

        <a-table :data-source="courses" :columns="columns" @change="handleChange" :row-key="record => record.id">

          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                  ref="searchInput"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined/>
                </template>
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
          </template>

          <template #customRender="{ text }">
            {{ text }}
          </template>

          <template #actions="{ record,index }">

            <a-space>
              <a-button type="primary" @click="showMyModal(record)">
                <EditOutlined/>
                Düzenle
              </a-button>
              <a-button @click="hideOrShow(record.id,index)" :loading="hideOrShowButtons[index]">
                {{ record.hidden ? 'Göster' : 'Gizle' }}
              </a-button>
            </a-space>


          </template>
        </a-table>
      </a-col>

    </a-card>
  </a-row>
</template>

<script>
import {EditOutlined, UnorderedListOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons-vue'
import {onMounted, reactive, computed, getCurrentInstance, toRefs, ref, inject} from 'vue'
import axios from 'axios'
import AddOrEditCourse from './components/AddOrEditCourse.vue'

export default {
  name: 'CourseList',
  components: {
    SearchOutlined,
    AddOrEditCourse,
    EditOutlined,
    PlusOutlined,
    UnorderedListOutlined
  },

  setup() {

    const localState = reactive({courses: [], filter: {}})
    const axios = inject('axios')
    const loading = ref(true)

    const addOrEditCourses = ref(null)

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    })

    const searchInput = ref()

    const getCourses = () => {

      axios.get('/courses')
          .then(response => {

            localState.courses = response.data.courses

            loading.value = false

          })
    }

    onMounted(() => {

      getCourses()

    })
    const columns = computed(() => {

      return [
        {
          key: 'id',
          title: 'ID',
          dataIndex: 'id',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.id.toString().toLowerCase().includes(value.toLowerCase()),

          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },

        {
          key: 'name',
          title: 'İsim',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'description',
          title: 'Açıklama',
          dataIndex: 'description',
          slots: {
            customRender: 'customRender'
          }
        },
        {
          key: 'category.name',
          title: 'Kategori',
          dataIndex: 'category.name',
          slots: {
            customRender: 'customRender'
          }
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          slots: {
            customRender: 'actions'
          }
        }

      ]
    })
    const handleChange = (pagination, filters, sorter) => {
      localState.filter = filters
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = clearFilters => {
      clearFilters()
      state.searchText = ''
    }

    const showMyModal = (selectedCourses) => {
      addOrEditCourses.value.showModal(selectedCourses)
    }

    const hideOrShowButtons = ref(new Array(localState.courses.length).fill(false))
    const hideOrShow = (id, index) => {
      hideOrShowButtons.value[index] = true
      axios.post(`/courses/${id}/hide-unhide`)
          .then(() => {
            getCourses()
          })
          .finally(() => hideOrShowButtons.value[index] = false)


    }


    return {
      addOrEditCourses,
      showMyModal,
      getCourses,
      ...toRefs(localState),
      hideOrShowButtons,
      columns,
      handleChange,
      loading,
      handleSearch,
      handleReset,
      searchText: '',
      searchInput,
      searchedColumn: '',
      hideOrShow
    }
  }

}
</script>
<style lang="scss" scoped>

.ant-popover-inner-content p {
  max-width: 295px;
  overflow-x: auto;
  max-height: 50px;
  white-space: nowrap;
  border-bottom: 1px solid #d8d8d8;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #d8d8d8;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #1890FF;
    border-radius: 2px;
  }
}
</style>
