<template>
  <a-modal
      :title="isEditing ?'Kurs Düzenle': 'Kurs Ekle'"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Kurs İsimi
      </a-col>

      <a-col>
        <a-input v-model:value="courseName" style="width: 600px"></a-input>
      </a-col>

    </a-row>

    <a-row type="flex">

      <a-col :flex="2">
        Kurs Açıklaması
      </a-col>

      <a-col>
        <a-input v-model:value="courseDescription" style="width: 600px"></a-input>
      </a-col>

    </a-row>

    <a-row type="flex">

      <a-col :flex="2">
        Kategori Seçin
      </a-col>

      <a-col>
        <a-select
            ref="category_select"

            v-model:value="selectedCategory"
            style="width: 600px"
            :options="categoryList"
        >
        </a-select>
      </a-col>

    </a-row>


    <template #footer>
      <a-button key="iptal" @click="visible = false">İptal</a-button>
      <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOk">
        {{ isEditing ? 'Düzenle' : 'Oluştur' }}
      </a-button>
    </template>

  </a-modal>
</template>

<script>
import { ref} from 'vue'
import axios from 'axios'

export default {
  name: 'AddOrEditCourse',
  emits: ['loadCourses'],
  setup(props, {emit}) {


    const isEditing = ref(false)

    const visible = ref(false)
    const confirmLoading = ref(false)

    const id = ref(null)
    const courseName = ref('')
    const courseDescription = ref('')

    const categoryList = ref([])
    const selectedCategory = ref(null)


    const showModal = async (_selectedCourse) => {

      await axios.get('/categories')
          .then((response)=>{

            categoryList.value = response.data.categories.map(item => ({label:item.name,value:item.id}))
            selectedCategory.value = categoryList.value[0].value

          })


      isEditing.value =false
      id.value = null
      courseName.value = ''
      courseDescription.value = ''

      if (_selectedCourse) {

        isEditing.value = true
        id.value = _selectedCourse.id
        selectedCategory.value = _selectedCourse.category.id
        courseName.value = _selectedCourse.name
        courseDescription.value = _selectedCourse.description
      }

      visible.value = true

    }

    const createCourse = async () => {
      return axios.post('/courses', {
        name: courseName.value,
        description: courseDescription.value,
        category_id:selectedCategory.value,
      })
    }

    const updateCourse = async () => {
      return axios.post('/courses/update', {
        course_id: id.value,
        category_id:selectedCategory.value,
        name: courseName.value,
        description: courseDescription.value
      })
    }


    const handleOk = () => {

      confirmLoading.value = true

      let funcToBeCalled = createCourse

      if (isEditing.value) {
        funcToBeCalled = updateCourse

      }

      funcToBeCalled()
          .then(() => {

            emit('loadCourses')

          })
          .finally(() => {
            visible.value = false
            confirmLoading.value = false
          })


    }


    return {
      selectedCategory,
      categoryList,
      courseName,
      courseDescription,
      isEditing,
      visible,
      confirmLoading,
      showModal,
      handleOk
    }

  }


}
</script>
